import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import Sidebard2024 from '../HealthAndWellnesClub/Sidebard2024';
import Archive2023 from '../HealthAndWellnesClub/Archive2023';

const ConditioningGirls2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioninggirls/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioninggirls/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioninggirls/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/clubs/HealthWellnessClub/Conditioninggirls/4.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 1.5,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 1.5,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                SOCIAL CONDITIONING FOR GIRLS
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    CLASS: 4 – 5(Girls)    Date: Wednesday, 5 March 2025
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                On Wednesday 5 March 2025, girls of Classes 4 and 5 attended a workshop on ‘Embrace the Power of Early Knowledge -A Period Friendly World’. The session was all about understanding the changes that happen during adolescence, both physical and emotional.
                                    <br></br>
                                    The objective of the workshop was to build confidence and stay positive during adolescence. The workshop was a safe space for question and answer session, learning and embracing the journey of growing up.

                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h4'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                              ‘Every month is a reminder of how strong and beautiful you are’

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Sidebard2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2023 />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ConditioningGirls2024;
 